/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import StandardPanelHeaderConfiguration from '../standard-panel-header-configuration';
import { and, readOnly, reads, union, filter } from '@ember/object/computed';
import { inject as service } from '@ember/service';

export default StandardPanelHeaderConfiguration.extend({
  contentEditorService: service(),
  ruleset: readOnly('contentEditorService.ruleset'),

  _validations: readOnly('contentEditorService.ruleset.validations'),
  _predicateGroupErrors: readOnly('_validations.attrs.predicateGroup.errors'),
  _userRolePredicateErrors: readOnly('_validations.attrs.userRolePredicate.errors'),
  _clientPredicateGroupErrors: readOnly('_validations.attrs.clientPredicateGroup.errors'),
  _rulesetTriggersErrors: readOnly('_validations.attrs.rulesetTriggers.errors'),
  _matchBehaviorErrors: readOnly('_validations.attrs.matchBehavior.errors'),
  _recurringScheduleErrors: filter(
    '_validations.attrs.recurringSchedule.errors',
    (error) => error.type === 'attribute-based-schedule-day-offset',
  ),

  _matchSpacingPeriodErrors: reads('_validations.attrs.matchSpacingPeriod.errors'),
  _maxMatchCountErrors: reads('_validations.attrs.maxMatchCount.errors'),

  errors: union(
    '_predicateGroupErrors',
    '_userRolePredicateErrors',
    '_clientPredicateGroupErrors',
    '_rulesetTriggersErrors',
    '_matchBehaviorErrors',
    '_matchSpacingPeriodErrors',
    '_maxMatchCountErrors',
    '_recurringScheduleErrors',
  ),
  isComplete: and(
    '_validations.attrs.predicateGroup.isValid',
    '_validations.attrs.userRolePredicate.isValid',
    '_validations.attrs.clientPredicateGroup.isValid',
    '_validations.attrs.rulesetTriggers.isValid',
    '_validations.attrs.matchBehavior.isValid',
    '_validations.attrs.matchSpacingPeriod.isValid',
    '_validations.attrs.maxMatchCount.isValid',
    '_validations.attrs.recurringSchedule.isValid',
  ),

  init(inputs) {
    inputs.component = inputs.component || 'content-editor/panels/audience/header';
    inputs.title = inputs.title || 'Rules';
    this._super(...arguments);
  },
});
