/* RESPONSIBLE TEAM: team-knowledge-foundations */

import type Folder from 'embercom/models/content-service/folder';
import type { EntityType } from 'embercom/models/data/entity-types';
import { type Movable } from 'embercom/objects/content-service/folders/movable';
import type FolderMembership from 'embercom/models/content-service/folder-membership';
import type Store from '@ember-data/store';

/**
 * Interface for any content item that can be a member of a folder, except for Folders themselves. Folders themselves
 * are not considered to be members of folders for the purposes of this interface as they do not use the
 * FolderMembership models to store their membership.
 *
 * This is the frontend equivalent of the ContentService::Interfaces::Folders::MembershipChild interface.
 *
 * Note that this is part of the Content Service, not the Knowledge Hub, and should be kept generic so that it
 * can be easily used outside of the Knowledge Hub in future.
 */
export interface MembershipChild extends Movable {
  folderMembership?: FolderMembership;
  /**
   * This returns the entity id of the content item, this is used to create the folder membership
   * You should only need to override this if the folder membership is not based on the content item
   */
  get entityIdForMembership(): number;
  /**
   * This returns the entity type of the content item, this is used to create the folder membership
   * You should only need to override this if the folder membership is not based on the content item
   */
  get entityTypeForMembership(): EntityType;
  /** Set the parent folder of this object. */
  set parent(parent: Folder | undefined);
  /** To trigger a success notification after an object has been moved */
  notifyMoveConfirmation(parent: Folder | undefined): void;
  notifyMoveError(): void;
  get store(): Store;
}

interface Args {
  contentItem: MembershipChild;
  destinationFolder?: Folder;
  disableConfirmation?: boolean;
}

export async function moveToFolderAndSave({
  contentItem,
  destinationFolder,
  disableConfirmation,
}: Args) {
  try {
    let folderMembership = contentItem.folderMembership;
    contentItem.parent = destinationFolder;
    if (!folderMembership && destinationFolder) {
      folderMembership = contentItem.store.createRecord('content-service/folder-membership', {
        entityId: contentItem.entityIdForMembership,
        entityType: contentItem.entityTypeForMembership,
      });
    }
    contentItem.folderMembership = folderMembership;
    await folderMembership?.moveToFolderAndSave({ folder: destinationFolder });
    if (folderMembership?.isDeleted) {
      contentItem.folderMembership = undefined;
    }
    if (!disableConfirmation) {
      contentItem.notifyMoveConfirmation(destinationFolder);
    }
  } catch (e) {
    contentItem.notifyMoveError();
  }
}
