/* RESPONSIBLE TEAM: team-workflows */
import EditorConfig from 'embercom/objects/content-editor/content-editor-configuration';
import generateViewModeConfig from 'embercom/objects/content-editor/configuration-generators/workflow/view-mode-generator';
import generateEditModeConfig from 'embercom/objects/content-editor/configuration-generators/workflow/edit-mode-generator';
import outboundBotConfigGenerator from 'embercom/objects/content-editor/configuration-generators/custom-bot';
import generateNotificationMessages from 'embercom/objects/content-editor/configuration-generators/standard-notification-messages-generator';
import generateBotNotificationMessages from 'embercom/objects/content-editor/configuration-generators/bot-notification-messages-generator';
import generateTriggerableBotNotificationMessages from 'embercom/objects/content-editor/configuration-generators/triggerable-custom-bot/notification-messages-generator';
import { objectTypes } from 'embercom/models/data/matching-system/matching-constants';
import { humanReadableObjectNames } from 'embercom/models/data/matching-system/matching-constants';
import { FIN_TASKS_ROUTES } from 'embercom/models/outbound/content-wrapper';

export default function generateEditorConfig(options) {
  let container = options.container;
  let contentEditorService = container.lookup('service:contentEditorService');
  let customBot = options.customBot;
  options.objectType = objectTypes.customBot; // all custom bot types resolve as 'bot'
  let intl = container.lookup('service:intl');
  let humanReadableObjectName = Object.values(FIN_TASKS_ROUTES).includes(
    options.container.router.currentRouteName,
  )
    ? 'task'
    : humanReadableObjectNames[options.objectType];
  let notificationMessages = generateNotificationMessages(options);

  if (customBot.isTriggerable) {
    let triggerableBotNotificationMessages = generateTriggerableBotNotificationMessages(
      container,
      humanReadableObjectName,
    );
    notificationMessages.activate = triggerableBotNotificationMessages.activate;
    notificationMessages.deactivate = triggerableBotNotificationMessages.deactivate;
    notificationMessages.delete = triggerableBotNotificationMessages.delete;
    notificationMessages.save = triggerableBotNotificationMessages.save;
    notificationMessages.schedule = triggerableBotNotificationMessages.schedule;
  } else {
    notificationMessages.activate = generateBotNotificationMessages(
      container,
      humanReadableObjectName,
    );
  }

  let paywallUpgradeHoverCopy = 'operator.custom-bot.header.set-live-paywall-text-with-workflows';

  let paywallUpgradeHoverHeading = 'operator.custom-bot.header.set-live-paywall-heading';

  if (contentEditorService.isSeriesMode) {
    return outboundBotConfigGenerator(options);
  }

  return EditorConfig.create({
    viewMode: generateViewModeConfig(options),
    editMode: generateEditModeConfig(options),
    notificationMessages,
    requiredFeature: customBot.config.requiredBillingFeature,
    paywallHoverParams: {
      customFeatureHeading: paywallUpgradeHoverHeading && intl.t(paywallUpgradeHoverHeading),
      customFeatureText: intl.t(paywallUpgradeHoverCopy),
    },
  });
}
